import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import UserAPI from "../../api/UserAPI";
import api from "../../api/api";
import { PrimaryButton, SecondaryButton, LinkButton } from "../Buttons/buttons";
import Popup from "./Popup";
import Utility from "../../utils/utility";
export const TEXT_NOTIFICATION_FIELD = "allowTextNotifications";

const DeleteAccountPopup = ({ isOpen, onClose, onChange }) => {


  if (!isOpen) return null;

  return (
    <Popup onCancel={onClose}>
      <ScheduleDeleteAccount />
    </Popup>
  );
};

function ScheduleDeleteAccount() {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [currentDeletionDate, setCurrentDeletionDate] = useState("90");
  const [showLoading, setShowLoading] = useState(false);
  // Simulate a stored deletion date (in real app this would come from backend)
  const [storedDeletionDate, setStoredDeletionDate] = useState();

  useEffect(() => {
    if (globalState.user) {
      let deleteOn = globalState.user?.accountDeletionDate;
      if (globalState.user?.accountDeletionDate) {
        deleteOn = Utility.convertDate(globalState.user?.accountDeletionDate);

      }
      setStoredDeletionDate(deleteOn);
    }

  }, [globalState.user])
  const deletionOptions = [

    { label: "2 Weeks", value: "14" },
    { label: "1 Month", value: "30" },
    { label: "3 Months", value: "90" },
    { label: "6 Months", value: "180" },
    { label: "1 Year", value: "365" },
    { label: "Never", value: "never" },
  ];

  const calculateTimeRemaining = (targetDate) => {
    if (!targetDate) return "No automatic deletion";

    const now = new Date();
    const timeDiff = targetDate - now;

    if (timeDiff <= 0) return "Deletion pending";

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} remaining`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} remaining`;
    } else {
      return `${days} day${days > 1 ? "s" : ""} remaining`;
    }
  };

  const handleSave = async () => {
    setShowLoading(true);
    const newDate =
      currentDeletionDate === "never"
        ? null
        : new Date(
          Date.now() + parseInt(currentDeletionDate) * 24 * 60 * 60 * 1000
        );
    await UserAPI.updateUser(
      globalState.user.userID,
      "accountDeletionDate",
      newDate)
    setGlobalState((prev) => ({
      ...prev,
      user: {
        ...globalState.user,
        accountDeletionDate: newDate,
      },
    }))

    setStoredDeletionDate(newDate);
    setShowLoading(false);

    setIsEditing(false);
  };



  return (
    <>
      <div className="flex flex-col justify-between items-center w-full h-full px-1">
        <h2 className="text-lg font-semibold text-gray-800">
          Account Deletion Settings
        </h2>
        <div
          className={`w-full mb-4 p-3 bg-gray-50 rounded-md border border-gray-200 cursor-pointer transition-all duration-300 ${isEditing ? "ring-2 ring-primary" : "hover:bg-gray-100"
            }`}
          onClick={() => setIsEditing(!isEditing)}
        >
          <div className="text-sm text-gray-600 flex justify-between items-center">
            <span>Current Setting</span>
            <svg
              className={`w-5 h-5 transform transition-transform duration-300 ${isEditing ? "rotate-180" : ""
                }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <div className="font-medium text-gray-800">
            {storedDeletionDate ? (
              <>
                <span className="text-primary">
                  {calculateTimeRemaining(storedDeletionDate)}
                </span>
                <span className="text-gray-500 text-sm ml-2">
                  (Until {storedDeletionDate.toLocaleDateString()})
                </span>
              </>
            ) : (
              <span className="text-primary">No automatic deletion set</span>
            )}
          </div>
        </div>

      </div>


      <div
        className={`w-full px-1 transition-all duration-300 overflow-hidden ${isEditing ? "max-h-full opacity-100" : "max-h-0 opacity-0"
          }`}
      >
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select New Deletion Period
          </label>
          <select
            value={currentDeletionDate}
            onChange={(e) => setCurrentDeletionDate(e.target.value)}
            className="w-full text-gray-700 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
          >
            {deletionOptions.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
          <p className="text-sm text-gray-500 mt-2">
            {currentDeletionDate === "never"
              ? "Your account will never be deleted"
              : `Account will be deleted after ${new Date(
                Date.now() +
                parseInt(currentDeletionDate) * 24 * 60 * 60 * 1000
              ).toLocaleDateString()}`}
          </p>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={() => {
              setIsEditing(false);
              setCurrentDeletionDate(
                storedDeletionDate
                  ? Math.ceil(
                    (storedDeletionDate - Date.now()) /
                    (1000 * 60 * 60 * 24)
                  ).toString()
                  : "never"
              );
            }}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={showLoading}
            className="px-4 py-2 bg-red   text-white rounded-md disabled:opacity-50"
          >
            {showLoading ? (
              <div className="flex items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                Saving...
              </div>
            ) : (
              "Schedule Delete"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default DeleteAccountPopup;
