import React, { useContext, useEffect, useState } from "react";
import {
  HomeIcon,
  TicketIcon,
  UserCircleIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { GlobalContext } from "../context/GlobalContext";
import { NavLink, useNavigate } from "react-router-dom";
import Utility from "../utils/utility";
import DeleteAccountPopup from "../components/Popup/DeleteAccount";

const ACTIVE_SESSION_FIELD = "activeSession";

function Nav() {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [showUserDeletionDialog, setShowUserDeletionDialog] = useState(false);
  const navigate = useNavigate();
  const isWithinThreshold = (
    dateToCheck,
    beforeThresholdHours,
    afterThresholdHours
  ) => {
    const now = new Date(); // Get the current time
    const timeDifference = dateToCheck - now; // Calculate the time difference in milliseconds
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours

    // Default the after threshold to the before threshold if not specified
    afterThresholdHours =
      typeof afterThresholdHours !== "undefined"
        ? afterThresholdHours
        : beforeThresholdHours;

    return (
      hoursDifference <= afterThresholdHours &&
      hoursDifference >= -beforeThresholdHours
    );
  };

  useEffect(() => {
    if (!globalState.user) return;
    const { accountDeletionDate } = globalState.user;
    const shouldShowUserDeletionDialog = accountDeletionDate &&
      isWithinNextTwoWeeks(Utility.convertDate(accountDeletionDate));

    setShowUserDeletionDialog(shouldShowUserDeletionDialog);
  }, [globalState.user]);

  const onLogo = () => {
    navigate("/");
  };
  function isWithinNextTwoWeeks(date) {
    // Get the current date and time
    const now = new Date();

    // Calculate the date two weeks from now
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(now.getDate() + 14);

    // Check if the given date is within the range
    return date >= now && date <= twoWeeksFromNow;
  }
  return (
    <>
      {
        globalState.showNav && (
          <nav className="fixed bottom-0 left-0 w-full h-16 flex justify-around items-center bg-white bg-opacity-85 shadow-custom z-10">
            <TabBtn name={"Events"} icon={<HomeIcon />} to="/" />
            {globalState.user != null ? (
              <>
                <TabBtn name={"Attending"} icon={<TicketIcon />} to="dates" />
                <Logo onLogo={onLogo} />
                <TabBtn name={"Matches"} icon={<SparklesIcon />} to="matches" />
              </>
            ) : (
              <Logo onLogo={onLogo} />
            )}
            <TabBtn name={"Profile"} icon={<UserCircleIcon />} to="profile" />
          </nav>
        )
      }
      {
        <DeleteAccountPopup
          isOpen={showUserDeletionDialog}
          onClose={() => setShowUserDeletionDialog(false)}
        />

      }
    </>

  );
}

const Logo = ({ onLogo }) => {
  return (
    <div className="flex items-center justify-center" onClick={onLogo}>
      <img src="/gist_logo.jpg" className="w-12 h-12 rounded-lg" />
    </div>
  );
};

function TabBtn({ name, icon, to }) {
  return (
    <NavLink
      className={({ isActive }) =>
        `flex flex-col items-center justify-center w-1/6 h-full text-gray-400 ${isActive ? "text-primary" : ""
        }`
      }
      to={to}
    >
      { }
      <div className="w-8 h-8">{icon}</div>
      <span className="text-xs">{name}</span>
    </NavLink>
  );
}

export default Nav;
