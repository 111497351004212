import React, { useEffect, useState, useContext } from "react";
import "./EventsList.scss";
import Api from "../api/api";
import Utility from "../utils/utility";
import { useNavigate } from "react-router-dom";
import EventsAPI from "../api/EventsAPI";
import {
  UsersIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import { GlobalContext } from "../context/GlobalContext";


const CACHE_KEY = 'events_cache';
const EventsList = ({ }) => {
  const [events, setEvents] = useState([]);
  const { globalState } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleEventClick = (event) => {
    navigate(`/event-details/${event.id}`, { state: { event } });
  };
  const fetchEvents = async () => {
    try {
      let setEventData;
      if (globalState.user) {
        const authEventsData = await EventsAPI.getAllEventsAuth();

        setEventData = authEventsData;
      } else {
        const response = await EventsAPI.getAllEvents();
        CacheService.set(CACHE_KEY, response);
        setEventData = response.data;
      }

      setEvents(setEventData);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  useEffect(() => {
    loadCachedData();
    fetchEvents();
  }, []);
  const loadCachedData = async () => {
    // First, try to load from cache
    const cachedData = CacheService.get(CACHE_KEY);
    if (cachedData) {
      setEvents(cachedData.data);
      return true
    }
    return false;
  }
  return (
    <div className="events-container hasNav">
      <h2 className="text-lg font-bold">Events</h2>
      <div className="list">
        {events.length ? (
          events.map((item) => {
            return (
              <EventItem
                key={item.id}
                item={item}
                onClick={(_) => handleEventClick(item)}
              />
            );
          })
        ) : (
          <div className="loader"></div>
        )}
      </div>
    </div>
  );
};

export default EventsList;

function EventItem({ item, onClick }) {
  if (item == undefined) return null;

  return (
    <div className="item-container" onClick={onClick}>
      <img src={item.photos[0]} alt={item.name} className="event-image" />
      <div className="info-container">
        <div className="title">{item.name}</div>
        <div className="time-place-age">
          <div className="date space-x-1">
            <CalendarDaysIcon />
            <div>{Utility.formatDate(item.date)}</div>
          </div>
          <div className="hostname space-x-1">
            <MapPinIcon />
            <div>{item.hostname}</div>
          </div>
          <div className="agerange space-x-1">
            <UsersIcon />
            <div>{item.ageRange}</div>
          </div>
        </div>
      </div>
    </div>
  );
}


const CacheService = {
  CACHE_KEY: 'events_cache',
  CACHE_DURATION: 5 * 60 * 1000,
  get: (key) => {
    try {
      const cachedData = localStorage.getItem(key);
      if (!cachedData) return null;

      const { timestamp, data } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > CacheService.CACHE_DURATION;

      return isExpired ? null : data;
    } catch (error) {
      console.error('Cache retrieval error:', error);
      return null;
    }
  },

  set: (key, data) => {
    try {
      const cacheData = {
        timestamp: Date.now(),
        data,
      };
      localStorage.setItem(key, JSON.stringify(cacheData));
    } catch (error) {
      console.error('Cache setting error:', error);
    }
  },

  clear: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Cache clearing error:', error);
    }
  },
};

