// File: src/components/CommonComponents.js
import React, { useState, useEffect, useContext } from "react";
import {
  ArrowRightStartOnRectangleIcon,
  ChatBubbleLeftRightIcon,
  BellAlertIcon,
  PencilIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import CircularProgressBar from "../../components/CircularProgressBar/CircularProgressBar";
import MenuDropdown from "../../components/MenuDropdown";
import Utility from "../../utils/utility";
import Popup from "../../components/Popup/Popup";
import QuestionDialog from "../../components/Popup/QuestionDialog";
import Sheets from "../../components/Sheets/Sheets";
import Order from "./Order";
import ProfileCard from "../../profile/ProfileCard";
import { GlobalContext } from "../../context/GlobalContext";
import EventsAPI from "../../api/EventsAPI";
import MatchAPI from "../../api/MatchAPI";
import AbstractChat from "../../components/AbstractChat/AbstractChat";
import { ProfileSelectorSheets } from "../CheckIn";
import { PrimaryButton } from "../../components/Buttons/buttons";

const ProgressBar = ({ currentRound, totalRounds }) => {
  const steps = [];

  for (let i = 1; i <= totalRounds; i++) {
    steps.push(
      <div
        key={i}
        className={`h-1 flex-1 rounded-full transition-all duration-300 ${i <= currentRound ? "bg-white" : "bg-white opacity-40"
          }`}
      ></div>
    );
  }

  return <div className="flex gap-2 w-full">{steps}</div>;
};

const Toolbar = ({
  user,
  onExit,
  setShowIDCard,
  currentRound,
  onUpdateCue = new Function(),
  onGroupChat = new Function(),
  onSelectProfiles = new Function(),
  onShowNotifications = new Function(),
  totalRounds,
  eventData
}) => {
  const onLogoClick = () => { };
  const { globalState } = useContext(GlobalContext);
  const onNameClcik = () => {
    setShowIDCard((prev) => {
      if (!user || !user.userID) return false;
      return !prev;
    });
  };

  const showSelectProfile = (_ => {
    if (eventData.eventType && eventData.eventType == 1) {
      const userType = Utility.getUserType(null, globalState?.user, eventData);
      const isMutualMatch = eventData.matchType == undefined || eventData.matchType == 'mutual';
      const isUserType = userType == eventData.matchType;
      if (isMutualMatch || isUserType) {

        return true;
      }
    }

    return false;
  })()
  const menuItems = [
    showSelectProfile && {
      label: "Select Profiles",
      icon: UsersIcon,
      action: onSelectProfiles,
    },
    {
      label: "Group Chat",
      icon: ChatBubbleLeftRightIcon,
      action: onGroupChat,
    },
    {
      label: "Update Cue",
      icon: PencilIcon,
      action: onUpdateCue,
    },
    {
      label: "Notifications",
      icon: BellAlertIcon,
      action: onShowNotifications,
    },
    {
      label: "Leave Event",
      icon: ArrowRightStartOnRectangleIcon,
      action: onExit,
      style:
        "text-rose-500 hover:bg-gray-100 hover:text-gray-900 border-t border-slate-200",
    },
  ].filter(Boolean);
  const getTitleText = (currentUsr) => {
    const userFirstName = currentUsr?.name?.split(" ")[0];
    return (
      <div className="flex items-center">
        <span>{userFirstName}</span>
        {currentUsr?.table && (
          <span className="ml-2 px-2 py-1 bg-primaryDark text-white text-xs font-medium rounded-lg">
            Table #{currentUsr.table}
          </span>
        )}
      </div>
    );
  };
  const nameOnTop = currentRound === 0 ? "" : getTitleText(user);

  return (
    <div>
      <div className="card-header">
        <div className="logo" onClick={onLogoClick}>
          <img src="/gist_logo.jpg" className="logo-img" />
        </div>
        <div className={`name select-none`} onClick={onNameClcik}>
          {nameOnTop}
        </div>
        <MenuDropdown menuItems={menuItems} ellipsisClassName="text-white" />
      </div>
      <div className="fixed top-2 z-2 left-0 right-0 px-5">
        <div className="flex justify-center w-full">
          <ProgressBar currentRound={currentRound} totalRounds={totalRounds} />
        </div>
      </div>
    </div>
  );
};

// TimerDisplay Component
const TimerDisplay = ({
  timeLeft,
  progress,
  isPaused,
  onPause,
  bio,
  SESSION_TIME,
  BUFFER_TIME,
}) =>
  timeLeft > SESSION_TIME - BUFFER_TIME ? (
    <div className="profile-description">
      <CircularProgressBar
        onPause={onPause}
        size={30}
        strokeWidth={3}
        percentage={progress}
        isPlaying={isPaused}
      />
      <p>{bio}</p>
    </div>
  ) : (
    <div className="timer">{Utility.formatDateSessionTime(timeLeft)}</div>
  );

// RatingReview Component
const RatingReview = ({ rating, setRating, commentText, onTextUpdate }) => {
  const [isStarsSet, setIsStarsSet] = useState(rating > 0 || false);
  const onStarsTap = (star) => {
    setRating(star);
    setIsStarsSet(true);
  };
  return (
    <div className="h-full w-full">
      <div className="area">Rate Event</div>
      <div className={`stars ${isStarsSet ? "active" : ""}`}>
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${rating >= star ? "selected" : ""}`}
            onClick={() => onStarsTap(star)}
          >
            ★
          </span>
        ))}
      </div>

      {isStarsSet && (
        <textarea
          value={commentText}
          onChange={onTextUpdate}
          placeholder="Rate Comments (optional)"
          rows="1"
          className="ratingInputField w-full p-5 rounded-md text-center text-sm"
        />
      )}
    </div>
  );
};
const BasePopup = ({
  isOpen,
  onClose,
  onConfirm,
  question,
  confirmBtnText,
  confirmCls,
  swapButtonStyles = false,
}) => {
  if (!isOpen) return null;

  return (
    <Popup onCancel={onClose}>
      <QuestionDialog
        onClosePopup={onClose}
        onConfirm={onConfirm}
        question={question}
        confirmBtnText={confirmBtnText}
        confirmCls={confirmCls}
        swapButtonStyles={swapButtonStyles}
      />
    </Popup>
  );
};

const NavNextPopup = ({ isOpen, onClose, onConfirm }) => (
  <BasePopup
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    question="Are you sure you want to move next?"
    confirmBtnText="Move Next"
  />
);

const EndSessionPopup = ({
  isOpen,
  onClose,
  onConfirm,
  rating,
  setRating,
  reviewText,
  setReviewText,
}) => (
  <BasePopup
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    question={
      <>
        <RatingReview
          rating={rating}
          setRating={setRating}
          commentText={reviewText}
          onTextUpdate={(e) => setReviewText(e.target.value)}
        />
        <p>Do you want to leave the event?</p>
      </>
    }
    confirmBtnText="Leave event"
    confirmCls="in-red"
    swapButtonStyles={false}
  />
);

const ReportUserPopup = ({
  isOpen,
  onClose,
  onConfirm,
  reportUserText,
  setReportUserText,
}) => (
  <BasePopup
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    question={
      <textarea
        value={reportUserText}
        onChange={(e) => setReportUserText(e.target.value)}
        placeholder={"Report Note"}
        rows="2"
        className="textfieldInput"
      />
    }
    confirmBtnText="Report User"
    confirmCls="in-red"
  />
);



const SessionSheet = ({
  onCancel,
  showSessionSheet,
  setShowSessionSheet,
  type,
  eventData,
}) => {
  const { globalState } = useContext(GlobalContext);
  const [sheetType, setSheetType] = useState(null);

  const onProfileUpdate = async (kv) => {
    await EventsAPI.onUpdateUserInQueue(
      kv,
      eventData,
      globalState.user,
      globalState.user.userID
    );
  };

  useEffect(() => {
    switch (type) {
      case "order":
        setSheetType(<Order setShowSessionSheet={setShowSessionSheet} />);
        break;
      case "profile":
        setSheetType(
          <ProfileCard showCue={true} onUserUpdate={onProfileUpdate} isSitter={Utility.getUserType(eventData.queue, globalState.user) == Utility.userType.sitters} />
        );
        break;
      case "group-chat":
        setSheetType(
          <AbstractChat
            getChat={MatchAPI.getMatchChat}
            addMessageToChat={MatchAPI.addMessageToChat}
            lastChecked={EventsAPI.lastCheckedChat}
            params={{
              eventID: eventData.id,
              userType: Utility.getUserType(eventData.queue, globalState.user),
              sitters: eventData.group.sitters,
              movers: eventData.group.movers,
            }}
          />
        );
        break;
      case "select-profiles":
        setSheetType(
          <ProfileSelectorSheets
            eventStreaming={eventData}
            globalState={globalState}
            handleStep={new Function()}
            hideSelectedProfiles={true}
          />
        );
        break;
      default:
        break;
    }
  }, [type, eventData, globalState]);

  return showSessionSheet && <Sheets onCancel={onCancel}>{sheetType}</Sheets>;
};
// Export components using named exports
export {
  Toolbar,
  TimerDisplay,
  RatingReview,
  NavNextPopup,
  EndSessionPopup,
  ReportUserPopup,
  SessionSheet,
  ProgressBar,
};
